<template>
<email-resposta />
</template>

<script>
import EmailResposta from '../layouts/EmailResposta.vue'

export default {
 name: "Obrigado",
  components: {
    EmailResposta,
  },
};
</script>

<style scoped>
</style>